
.riskProfile-section--questions {
    background: #f7f7f7;
    transition: 0.3s;
    border-radius: 5px;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
}

.riskProfile-section--questions:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.table-container {
    display: flex;
    justify-content: center;
    color: #555555;
    text-align: center;
    width: 100%;
    margin: 1em 0;
}

.table {
    border-collapse:collapse; 
    border: 1px solid #495C6F;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
}

.table-heading {
    background-color: #495C6F;
    color: #FFFFFF;
    padding: 0.5em;
    border-top: 1px solid #FFFFFF;
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
}

.table-data {
    padding: 0.5em;
    border-top: 1px solid #495C6F;
    border-left: 1px solid #495C6F;
    border-right: 1px solid #495C6F;
}

.table-data:first-child {
    border-left: none;
}

.table-data:last-child {
    border-right: none;
}