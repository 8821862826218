
.LoginContainer {
  height: 100vh;
  display: flex;
}
  
.logo-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}
  
.content-box {
  color: white;
  flex: 1;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
  
.content-box h3 {
  font-size: 36px;
  font-weight: 700;
  text-decoration: underline #FCC007;
  margin-bottom: 40px;
}
  
.addButton {
  margin: 5px;
  padding: 10px;
  color: white;
  background-color: #29B5C3;
  border: 0;
  border-radius: 5px;
}

.content-box form label {
  display: block;
  width: 100%;
  font-size: 16px;
}
  
.content-box form input, .content-box form button {
  padding: 0 10px;
}


.content-box form * {
  margin: 10px 0;
}

.content-box form input {
  color: #000000;
  margin: 0px;
  border: 1px solid #29B5C3;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  padding: 8px;
}
  
.content-box form button {
  background-color: white;
  border: 0;
  transition: 0.3s;
}
  
.content-box form button:hover {
  background-color:#000000;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}

.content-box form button {
  color: #000000;
  padding: 10px;
  border: 1px solid #29B5C3;
}

.containeradmin {
  display:flex;
}

.admin-sidebar {
  flex-basis: 20%;
  background-color: #f4f4f4;
  padding: 0;
  height:100vh;
}

.admin-sidebar ul li {
  padding: 20px;
  font-size: 20px;
  list-style: none;;
  cursor: pointer;
}

.dashboard {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px;
}

.dashboard h3 {
  text-align: center;
}

.dashboard-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.menu-box {
    padding: 10px;
    border: 0.5px solid #cccccc;
    text-align: center;
    cursor: pointer;
}

.card {
    background: #f7f7f7;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
}

.card img {
  width: 100px;
  height: 100px;
}

.card:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

#saveForm {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

#saveForm .container input, textarea {
  color: #000000;
  margin: 0px;
  border: 1px solid #29B5C3;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  padding: 8px;
}

.labelInput {
  margin: 15px;
  padding: 3px;
}

#saveForm .container button {
  padding: 10px;
  color: #FFFFFF;
  background-color: #29B5C3;
  border-radius: 3px;
  border: 0px;
  margin: 0 15px;
  cursor: pointer;
}

#saveForm .container textarea {
  margin: 0px;
  border: 1px solid #29B5C3;
  border-radius: 5px;
  width: 100%;
  padding: 0px 5px;
  font-size: 15px;
}

#saveForm .container label {
  margin: 0px;
  font-size: 16px;
}

#saveForm>.form {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 20px;
}

#saveForm .form .gridFull {
  grid-column: 1/3;
}

#fetchEvents {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

.fetchAuthor {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

.list-container {
  
}

/* .containeradmin table, th, td {
  width: 80%;
  margin: auto;
  border: 1px solid #29B5C3;
  border-collapse: collapse;
  text-align: center;
  font-size: 16px;
  table-layout: fixed;
}

.containeradmin td, th {
  padding: 10px;
} */

.modal-content *{
  margin: 5px 0;
}

.modalFetchEventBT {
  margin: 5px 5px 5px 0;
  padding: 5px;
  border: 0;
  border-radius: 5px;
  background: #29B5C3;
  color: white;
}

#annoucement-content {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

#annoucementForm button {
  padding: 5px;
  color: #FFFFFF;
  background-color: #29B5C3;
  border: 0px;
}

#blog-dashboard {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px;
}

.blog-dashboard-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

#blog-dashboard h3 {
  text-align: center;
}

#Author {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

#addAuthorForm .MainContainer input, textarea {
  color: #000000;
  margin: 0px;
  border-radius: 5px;
  width: 100%;
  padding: 0 7px;
  font-size: 16px;
}

.addAuthorForm-inputs {
  display: flex;
  align-items: center;
}

.addAuthor-section {
  display: flex;
  align-items: center;
  align-content: center;
}

.autherForm {
  flex: 1;
}

.author-image {
  flex: 1;
}

.session-container {
  display: flex;
  align-items: center;
  padding-left: 40px;
}


@media only screen and (max-width:560px){
  .LoginContainer{
    flex-direction: column;
  }

  .logo-box img {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .admin-sidebar {
    display: none;
  }

  .dashboard {
    justify-items: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
  }

  #saveForm {
    flex-basis: 100%;
  }

  #saveForm .form{
    display: block;
  }
}