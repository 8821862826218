.user {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 2em 0;
}

.user__container {
    width: 60%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

/* .form {
    padding: 1em 0.75em !important;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
} */

.navigation {
    width: 100%;
}

.navigation__nav {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.navigation__nav--link {
    text-decoration: none;
    padding: 1em;
    border: 1px solid #e0e0e0;
}

.navigation__advert {
    display: flex;
    flex-direction: column;
    margin-top: 0.5em
}

.navigation__advert--image {
    width: 100%;
}

.center-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/*  for icons in account  */
.app-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.app-icon__icon {
    margin: 2em 1em 1em 1em;
}

.app-icon__name {
    word-wrap: break-word;
    text-align: center;
    margin: 0 2em 1.5em 2em;
}

.app-transaction {
    display: flex;
    flex-direction: column;
    margin: 1em 0 1em 0;
    border-top: 1px solid #e0e0e0;
}

.app-transaction--heading {
    text-align: center;
    margin: 1.5em 0;
}

.app-transaction--links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.app-transaction--links-link {
    color: #fff;
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    padding: 0.5em 1em;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.hidden {
    display: none;
}

@media (max-width: 959px) {
    .user__container {
        width: 100%;
    }

    .navigation__nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 1em;
    }

    .navigation__advert {
        display: none;
    }

    .app-transaction--links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .app-transaction--links-link {
        margin: 0.5em 1.5em;
    }
}