body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.shine {
  height: 20px;
  width: 80%;
  background-image: -webkit-radial-gradient(
    50% -50%,
    farthest-side,
    hsla(0, 0%, 0%, 0.5),
    hsla(0, 0%, 0%, 0));
  background-image: radial-gradient(
    farthest-side at 50% -50%,
    hsla(0, 0%, 0%, 0.5),
    hsla(0, 0%, 0%, 0));
  position: relative; 
}

.shine::before {
  height: 1px;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  background-image: -webkit-linear-gradient(
    left,
    hsla(0, 0%, 0%, 0),
    hsla(0, 0%, 0%, 0.75) 50%,
    hsla(0, 0%, 0%, 0));
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 0%, 0),
    hsla(0, 0%, 0%, 0.75) 50%,
    hsla(0, 0%, 0%, 0));
}

hr {
  border: 0;
  margin: 1.35em auto;
  max-width: 100%;
  background-position: 50%;
  box-sizing: border-box;
}
.events {
    background-image: url(/static/media/events.23e17c7d.jpg);
}

.event {
    text-align: center;
}

.event-table {
    font-size: 12px;
}

.btn-event {
    background-color: transparent;
    color: #000;
    box-shadow: none;
}

.btn-event:hover {
    background-color: #fff;
    box-shadow: none;
    color: #29B5C3;
}

@media (max-width: 600px) {
    .event-table {
        font-size: 8px;
    }
}
.box {
    border: 1px solid #000;
}

.video {
    width: 600px;
    height: 350px;
    margin: 15px;
}

.image {
    width: 95%;
    height: 180px;
    margin: 0.5em;
}

.heading-media {
    font-size: 2.5rem !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
}

.image-link {
    width: 100%;
}

.text-align {
    text-align: center;
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: 1px;
}

.video-list--item {
    cursor: pointer;
    padding: 0 10px;
}

.video-list--item:hover {
    color: #29B5C3;
}

.title {
    font-size: 14px !important;
    font-weight: 700 !important;
    margin-top: 0;
}

.description div {
    color: red;
    font-weight: bold;
    text-align: right;
    margin-right: 0.5em;
}

.page-font {
    font-size: 16px !important;
    font-weight: 700;
    letter-spacing: 1px !important;
}

@media screen and (max-width: 992px){
    .video {
        width: 100%;
    }

    .image {
        height: 150px;
    }
}

@media screen and (max-width: 600px){
    .video {
        width: 100%;
        height: auto;
    }

    .image {
        height: 150px;
    }
}
.about {
    text-align: center;
    margin: 3rem;
}

.about h3 {
    font-size: 30px;
    margin-bottom: .2rem;
}

.about span {
    letter-spacing: .2rem;
}

.team {
    padding: 2rem;
}

.team-member {
    overflow: hidden;
    position: relative;
}

.team-photo img {
    width: 100%;
}

.team-member:hover .team-hover {
    opacity: 1;
    height: 100%;
}

.team-member .team-text {
    top: 50%;
    left: 0;
    width: 100%;
    height: 130px;
    padding: 20px 0;
    margin-top: -65px;
    background: #fff;
    position: absolute;
}

.team-text h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.team-text span {
    display: block;
}

.social-icons a {
    font-size: 22px;
    color: grey;
    margin: 0 10px;
    padding: 20px 0;
}

.team-hover {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    opacity: 0;
    width: 100%;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    background-color: rgba(27, 41, 50, 0.9);
}
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

.center-content {
    margin: 0 auto;
}

.box {
    border: 1px solid #000;
}

.app {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgb(205, 232, 255);
    width: 100%;
}

.app__container {
    width: 70%;
    background-color: #FFF;
    border-radius: 5px;
    padding: 1em 0 0 0;
    margin: 2em 0;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
}

.bread__container {
    width: 70%;
    border-radius: 5px;
    margin: 1em 0;
}

.gender {
    margin-top: 1em;
    margin-left: 1em;
}

/*  for range inputs  */
.form--range-input {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 0.1em;
}

.form--range-display {
    border-radius: 25px;
    padding: .5em;
    margin: 0.3em 0.2em 0.3em 0.8em;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
}

/*  form summary  */
.form--summary {
    padding: 1.5em 3.5em;
    margin: 1.5em 1em;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
}

.form--summary-details {
    line-height: 1.7 !important;
}

.form--summary-output {
    font-weight: 600;
    border: 1px solid #29B5C3;
    border-radius: 2px;
    padding: 5px;
    margin: 2px;
}

/*  final output display  */
.form--output {
    font-size: 1.5rem;
    font-weight: 600;
}

#riskProfileNote1 {
    margin: 2.5em 3.5em 1.5em 3.5em;  
}

.note1 {
    color: blue;
    margin-right: 1em;
}

#riskProfileNote2 {
    margin: 2.5em 3.5em 1.5em 3.5em;  
}

.note2 {
    color: red;
    margin-right: 1em;
}

#tryNewParagraph, #monthlyInvestmentParagraph {
    margin: 2.5em 3.5em 1.5em 3.5em;
}

.meter {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin: 3em auto 2em 0;
}

.gauge-meter {
    position: relative;
    width: 250px;
    height: 150px;
}

.conservative {
    position: absolute;
    top: 25px;
    width: 40%;
    cursor: pointer;
    opacity: 0.9;
}

.moderate {
    position: absolute;
    top: 8px;
    left: 63px;
    width: 48%;
    cursor: pointer;
    opacity: 0.9;
}

.aggressive {
    position: absolute;
    top: 26px;
    right: 5px;
    width: 40%;
    cursor: pointer;
    opacity: 0.9;
}

.pointer {
    position: absolute;
    width: 10%;
    top: 50px;
    left: 110px;
    -webkit-transform: translateX(-30px) translateY(35px) rotate(-70deg);
            transform: translateX(-30px) translateY(35px) rotate(-70deg);
}

.hidden {
    display: none;
}


@media screen and (max-width: 992px) {
    .app__container {
        width: 70%;
    }

    .gender {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .app {
        margin: 0;
    }

    .app__container {
        width: 100%;
    }

    .form--summary {
        margin: 1.5em 0.2em;
    }

    .form--summary-details {
        line-height: 2 !important;
    }

    .form--summary-output {
        padding: 2px;
        margin: 2px;
    }
}



.financial-section--about {
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-gap: 15px;
}

.financial-section--about:first-child {
    margin-left: 10px;
}

.financial-section--about:last-child {
    margin-right: 10px;
}

.financial-section--questions {
    background: #f7f7f7;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 5px;
    padding: 15px;
}

.financial-section--questions:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

@media(max-width: 560px){
    .financial-section--about {
        grid-template-columns: repeat(1,1fr);
    }
}

.riskProfile-section--questions {
    background: #f7f7f7;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 5px;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
}

.riskProfile-section--questions:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.table-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #555555;
    text-align: center;
    width: 100%;
    margin: 1em 0;
}

.table {
    border-collapse:collapse; 
    border: 1px solid #495C6F;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
}

.table-heading {
    background-color: #495C6F;
    color: #FFFFFF;
    padding: 0.5em;
    border-top: 1px solid #FFFFFF;
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
}

.table-data {
    padding: 0.5em;
    border-top: 1px solid #495C6F;
    border-left: 1px solid #495C6F;
    border-right: 1px solid #495C6F;
}

.table-data:first-child {
    border-left: none;
}

.table-data:last-child {
    border-right: none;
}
.hide-result {
    display: none;
}

.user {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin: 2em 0;
}

.user__container {
    width: 60%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

/* .form {
    padding: 1em 0.75em !important;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
} */

.navigation {
    width: 100%;
}

.navigation__nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.navigation__nav--link {
    text-decoration: none;
    padding: 1em;
    border: 1px solid #e0e0e0;
}

.navigation__advert {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 0.5em
}

.navigation__advert--image {
    width: 100%;
}

.center-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}

/*  for icons in account  */
.app-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.app-icon__icon {
    margin: 2em 1em 1em 1em;
}

.app-icon__name {
    word-wrap: break-word;
    text-align: center;
    margin: 0 2em 1.5em 2em;
}

.app-transaction {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 1em 0 1em 0;
    border-top: 1px solid #e0e0e0;
}

.app-transaction--heading {
    text-align: center;
    margin: 1.5em 0;
}

.app-transaction--links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.app-transaction--links-link {
    color: #fff;
    background: -webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    padding: 0.5em 1em;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.hidden {
    display: none;
}

@media (max-width: 959px) {
    .user__container {
        width: 100%;
    }

    .navigation__nav {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: center;
                justify-content: center;
        margin-top: 1em;
    }

    .navigation__advert {
        display: none;
    }

    .app-transaction--links {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .app-transaction--links-link {
        margin: 0.5em 1.5em;
    }
}

.LoginContainer {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
}
  
.logo-box {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: whitesmoke;
}
  
.content-box {
  color: white;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
  
.content-box h3 {
  font-size: 36px;
  font-weight: 700;
  -webkit-text-decoration: underline #FCC007;
          text-decoration: underline #FCC007;
  margin-bottom: 40px;
}
  
.addButton {
  margin: 5px;
  padding: 10px;
  color: white;
  background-color: #29B5C3;
  border: 0;
  border-radius: 5px;
}

.content-box form label {
  display: block;
  width: 100%;
  font-size: 16px;
}
  
.content-box form input, .content-box form button {
  padding: 0 10px;
}


.content-box form * {
  margin: 10px 0;
}

.content-box form input {
  color: #000000;
  margin: 0px;
  border: 1px solid #29B5C3;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  padding: 8px;
}
  
.content-box form button {
  background-color: white;
  border: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
  
.content-box form button:hover {
  background-color:#000000;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}

.content-box form button {
  color: #000000;
  padding: 10px;
  border: 1px solid #29B5C3;
}

.containeradmin {
  display:-webkit-flex;
  display:flex;
}

.admin-sidebar {
  -webkit-flex-basis: 20%;
          flex-basis: 20%;
  background-color: #f4f4f4;
  padding: 0;
  height:100vh;
}

.admin-sidebar ul li {
  padding: 20px;
  font-size: 20px;
  list-style: none;;
  cursor: pointer;
}

.dashboard {
  -webkit-flex-basis: 80%;
          flex-basis: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 15px;
}

.dashboard h3 {
  text-align: center;
}

.dashboard-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.menu-box {
    padding: 10px;
    border: 0.5px solid #cccccc;
    text-align: center;
    cursor: pointer;
}

.card {
    background: #f7f7f7;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 5px;
}

.card img {
  width: 100px;
  height: 100px;
}

.card:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

#saveForm {
  -webkit-flex-basis: 80%;
          flex-basis: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 10px;
}

#saveForm .container input, textarea {
  color: #000000;
  margin: 0px;
  border: 1px solid #29B5C3;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  padding: 8px;
}

.labelInput {
  margin: 15px;
  padding: 3px;
}

#saveForm .container button {
  padding: 10px;
  color: #FFFFFF;
  background-color: #29B5C3;
  border-radius: 3px;
  border: 0px;
  margin: 0 15px;
  cursor: pointer;
}

#saveForm .container textarea {
  margin: 0px;
  border: 1px solid #29B5C3;
  border-radius: 5px;
  width: 100%;
  padding: 0px 5px;
  font-size: 15px;
}

#saveForm .container label {
  margin: 0px;
  font-size: 16px;
}

#saveForm>.form {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 20px;
}

#saveForm .form .gridFull {
  grid-column: 1/3;
}

#fetchEvents {
  -webkit-flex-basis: 80%;
          flex-basis: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 10px;
}

.fetchAuthor {
  -webkit-flex-basis: 80%;
          flex-basis: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 10px;
}

.list-container {
  
}

/* .containeradmin table, th, td {
  width: 80%;
  margin: auto;
  border: 1px solid #29B5C3;
  border-collapse: collapse;
  text-align: center;
  font-size: 16px;
  table-layout: fixed;
}

.containeradmin td, th {
  padding: 10px;
} */

.modal-content *{
  margin: 5px 0;
}

.modalFetchEventBT {
  margin: 5px 5px 5px 0;
  padding: 5px;
  border: 0;
  border-radius: 5px;
  background: #29B5C3;
  color: white;
}

#annoucement-content {
  -webkit-flex-basis: 80%;
          flex-basis: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 10px;
}

#annoucementForm button {
  padding: 5px;
  color: #FFFFFF;
  background-color: #29B5C3;
  border: 0px;
}

#blog-dashboard {
  -webkit-flex-basis: 80%;
          flex-basis: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 15px;
}

.blog-dashboard-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

#blog-dashboard h3 {
  text-align: center;
}

#Author {
  -webkit-flex-basis: 80%;
          flex-basis: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 10px;
}

#addAuthorForm .MainContainer input, textarea {
  color: #000000;
  margin: 0px;
  border-radius: 5px;
  width: 100%;
  padding: 0 7px;
  font-size: 16px;
}

.addAuthorForm-inputs {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.addAuthor-section {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}

.autherForm {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.author-image {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.session-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 40px;
}


@media only screen and (max-width:560px){
  .LoginContainer{
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .logo-box img {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .admin-sidebar {
    display: none;
  }

  .dashboard {
    justify-items: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }

  #saveForm {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }

  #saveForm .form{
    display: block;
  }
}
