
.financial-section--about {
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-gap: 15px;
}

.financial-section--about:first-child {
    margin-left: 10px;
}

.financial-section--about:last-child {
    margin-right: 10px;
}

.financial-section--questions {
    background: #f7f7f7;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    padding: 15px;
}

.financial-section--questions:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

@media(max-width: 560px){
    .financial-section--about {
        grid-template-columns: repeat(1,1fr);
    }
}