.about {
    text-align: center;
    margin: 3rem;
}

.about h3 {
    font-size: 30px;
    margin-bottom: .2rem;
}

.about span {
    letter-spacing: .2rem;
}

.team {
    padding: 2rem;
}

.team-member {
    overflow: hidden;
    position: relative;
}

.team-photo img {
    width: 100%;
}

.team-member:hover .team-hover {
    opacity: 1;
    height: 100%;
}

.team-member .team-text {
    top: 50%;
    left: 0;
    width: 100%;
    height: 130px;
    padding: 20px 0;
    margin-top: -65px;
    background: #fff;
    position: absolute;
}

.team-text h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.team-text span {
    display: block;
}

.social-icons a {
    font-size: 22px;
    color: grey;
    margin: 0 10px;
    padding: 20px 0;
}

.team-hover {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    opacity: 0;
    width: 100%;
    transition: 0.3s ease;
    background-color: rgba(27, 41, 50, 0.9);
}