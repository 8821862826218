.box {
    border: 1px solid #000;
}

.video {
    width: 600px;
    height: 350px;
    margin: 15px;
}

.image {
    width: 95%;
    height: 180px;
    margin: 0.5em;
}

.heading-media {
    font-size: 2.5rem !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
}

.image-link {
    width: 100%;
}

.text-align {
    text-align: center;
    font-size: 16px !important;
    font-weight: 700 !important;
    letter-spacing: 1px;
}

.video-list--item {
    cursor: pointer;
    padding: 0 10px;
}

.video-list--item:hover {
    color: #29B5C3;
}

.title {
    font-size: 14px !important;
    font-weight: 700 !important;
    margin-top: 0;
}

.description div {
    color: red;
    font-weight: bold;
    text-align: right;
    margin-right: 0.5em;
}

.page-font {
    font-size: 16px !important;
    font-weight: 700;
    letter-spacing: 1px !important;
}

@media screen and (max-width: 992px){
    .video {
        width: 100%;
    }

    .image {
        height: 150px;
    }
}

@media screen and (max-width: 600px){
    .video {
        width: 100%;
        height: auto;
    }

    .image {
        height: 150px;
    }
}