*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

.center-content {
    margin: 0 auto;
}

.box {
    border: 1px solid #000;
}

.app {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgb(205, 232, 255);
    width: 100%;
}

.app__container {
    width: 70%;
    background-color: #FFF;
    border-radius: 5px;
    padding: 1em 0 0 0;
    margin: 2em 0;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
}

.bread__container {
    width: 70%;
    border-radius: 5px;
    margin: 1em 0;
}

.gender {
    margin-top: 1em;
    margin-left: 1em;
}

/*  for range inputs  */
.form--range-input {
    display: flex;
    flex-direction: row;
    margin-top: 0.1em;
}

.form--range-display {
    border-radius: 25px;
    padding: .5em;
    margin: 0.3em 0.2em 0.3em 0.8em;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
}

/*  form summary  */
.form--summary {
    padding: 1.5em 3.5em;
    margin: 1.5em 1em;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
}

.form--summary-details {
    line-height: 1.7 !important;
}

.form--summary-output {
    font-weight: 600;
    border: 1px solid #29B5C3;
    border-radius: 2px;
    padding: 5px;
    margin: 2px;
}

/*  final output display  */
.form--output {
    font-size: 1.5rem;
    font-weight: 600;
}

#riskProfileNote1 {
    margin: 2.5em 3.5em 1.5em 3.5em;  
}

.note1 {
    color: blue;
    margin-right: 1em;
}

#riskProfileNote2 {
    margin: 2.5em 3.5em 1.5em 3.5em;  
}

.note2 {
    color: red;
    margin-right: 1em;
}

#tryNewParagraph, #monthlyInvestmentParagraph {
    margin: 2.5em 3.5em 1.5em 3.5em;
}

.meter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 3em auto 2em 0;
}

.gauge-meter {
    position: relative;
    width: 250px;
    height: 150px;
}

.conservative {
    position: absolute;
    top: 25px;
    width: 40%;
    cursor: pointer;
    opacity: 0.9;
}

.moderate {
    position: absolute;
    top: 8px;
    left: 63px;
    width: 48%;
    cursor: pointer;
    opacity: 0.9;
}

.aggressive {
    position: absolute;
    top: 26px;
    right: 5px;
    width: 40%;
    cursor: pointer;
    opacity: 0.9;
}

.pointer {
    position: absolute;
    width: 10%;
    top: 50px;
    left: 110px;
    transform: translateX(-30px) translateY(35px) rotate(-70deg);
}

.hidden {
    display: none;
}


@media screen and (max-width: 992px) {
    .app__container {
        width: 70%;
    }

    .gender {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .app {
        margin: 0;
    }

    .app__container {
        width: 100%;
    }

    .form--summary {
        margin: 1.5em 0.2em;
    }

    .form--summary-details {
        line-height: 2 !important;
    }

    .form--summary-output {
        padding: 2px;
        margin: 2px;
    }
}

