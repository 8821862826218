.events {
    background-image: url('../../images/events.jpg');
}

.event {
    text-align: center;
}

.event-table {
    font-size: 12px;
}

.btn-event {
    background-color: transparent;
    color: #000;
    box-shadow: none;
}

.btn-event:hover {
    background-color: #fff;
    box-shadow: none;
    color: #29B5C3;
}

@media (max-width: 600px) {
    .event-table {
        font-size: 8px;
    }
}